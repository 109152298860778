import {FETCH} from "@/utils/list.js";
import payoutAPI from "@/api/payout.js";
const TYPES = {
  RESET: "PAYOUT_RESET",
  FETCH_PAYOUT: "PAYOUT_FETCH_PAYOUT",
  FETCH_HISTORY: "PAYOUT_FETCH_HISTORY",
  TO_PAGE_HISTORY: "PAYOUT_TO_PAGE_HISTORY",
  PAGINATION_HISTORY: "PAYOUT_PAGINATION_HISTORY",
  SET_PAYMENT_PROVIDER_STATUS: "SET_PAYMENT_PROVIDER_STATUS",
}
const defaultState = () => {
  return {
    history: {
      list: [],
      loading: false,
      fetched: false,
      pagination: {
        limit: 10,
        page: 1,
        total: 1
      }
    },
    payout: null,
    isPaymentProviderSet: false
  }
}
export default {
  state: defaultState(),

  mutations: {
    [TYPES.FETCH_PAYOUT](state, payout) {
      state.payout = payout;
    },

    [TYPES.TO_PAGE_HISTORY](state, page) {
      state.history.pagination.page = page;
    },
    [TYPES.FETCH_HISTORY](state, list) {
      state.history.list = list || [];
    },
    [TYPES.PAGINATION_HISTORY](state, pagi) {
      state.history.pagination = pagi;
    },
    [TYPES.SET_PAYMENT_PROVIDER_STATUS](state, status) {
      state.isPaymentProviderSet  = status;
    },
    [TYPES.RESET](state) {
      let initial = defaultState();
      Object.keys(initial).forEach(key => {
        state[key] = initial[key];
      });
      console.log({
        message: TYPES.RESET,
        state
      })
    }
  },

  actions: {
    fetchPayoutHistory(store, to) {
      let history = store.state.history;
      if (to) {
        store.commit(TYPES.TO_PAGE_HISTORY, to);
      }
      let {limit, page} = history.pagination;
      history.loading = true;

      payoutAPI.getPaid(limit, page).then(res => {
        // console.log(history)
        history.loading = false;
        history.fetched = true;
        let {pagination, history: h} = res.data;

        store.commit(TYPES.FETCH_HISTORY, h);
        store.commit(TYPES.PAGINATION_HISTORY, pagination);
      })
      // .catch(err => {
      //   console.warn({err})
      //   history.loading = false;
      //   store.commit("OpenError", {
      //     title: "ERROR",
      //     message: (err.response && err.response.data && err.response.data.message) || "Can't get payout history."
      //   })
      // })
    },
    updatePaymentProviderStatus(store, status) {
      store.commit('SET_PAYMENT_PROVIDER_STATUS', status);
    },
  },
};
